import { FlexBox, TextField, theme, trackEvent } from '@rario/shared-components'
import { CookieConsentTextWrapper, CookieConsentWrapper } from 'components/Layout/Layout.styles'
import { ConsentManager } from '@segment/consent-manager'
import { SEGMENT_KEY } from 'config'
import { PreferencesDialogBoxContent } from 'constant'

const CookieConsentBox: React.FunctionComponent = () => {
  const bannerContent = (
    <FlexBox flexDirection={'column'} m={'3px 0px 0px 10px'}>
      <TextField
        color={theme.colors.chineseBlack}
        fontFamily={theme.fonts.primary}
        letterSpacing={theme.letterSpacings.primary}
        fontWeight={700}
        fontSize={'18px'}
        lineHeight={'22px'}
      >
        Improving Your Experience ✨
      </TextField>
      <CookieConsentTextWrapper>
        We use cookies to collect data to improve your experience on our site. By using our website,
        you agree to the collection of data as described in our{' '}
        <a
          href="/privacy-policy?referer=home"
          target="_blank"
          onClick={() => {
            trackEvent('Section Interacted', 'click', {
              sub_category: 'cookie_consent_manager-banner_policy_link',
              click_type: 'text_url',
              category: 'cookie_consent_manager',
              click_text: 'Website Data Collection Policy',
              redirect_url: '/privacy-policy',
              click_action: 'click_data_collection_policy_link',
            })
          }}
        >
          Website Data Collection Policy
        </a>
        .
      </CookieConsentTextWrapper>
    </FlexBox>
  )
  const bannerSubContent = 'You can change your preferences here.'
  const preferencesDialogTitle = 'Website Data Collection Preferences'
  const preferencesDialogContent =
    'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.'
  const cancelDialogTitle = 'Are you sure you want to cancel?'
  const cancelDialogContent = `Your preferences have not been saved. By continuing to use our website, you're agreeing to our Website Data Collection Policy.`

  const CATEGORIES = {
    functional: true,
    marketingAndAnalytics: true,
    advertising: false,
  }

  return (
    <CookieConsentWrapper
      width={['100%', theme.maxDesktopLayoutWidth]}
      position={'fixed'}
      bottom={0}
      zIndex={99999}
      left={'50%'}
    >
      <ConsentManager
        writeKey={SEGMENT_KEY || ''}
        bannerContent={bannerContent}
        bannerSubContent={bannerSubContent}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
        bannerBackgroundColor={theme.colors.etonBlue}
        bannerTextColor={theme.colors.black}
        initialPreferences={CATEGORIES}
        preferencesDialogTemplate={PreferencesDialogBoxContent}
        closeBehavior={() => {
          trackEvent('Section Interacted', 'click', {
            sub_category: 'cookie_consent_manager-banner_close_icon',
            click_type: 'icon',
            category: 'cookie_consent_manager',
            click_action: 'close_consent_manager_banner',
          })
          return {
            functional: true,
            marketingAndAnalytics: true,
            advertising: true,
          }
        }}
      />
    </CookieConsentWrapper>
  )
}

export default CookieConsentBox
