import { AppProps } from 'next/app'
import Layout from 'components/Layout/Layout'
import { ThemeProvider } from '@rario/shared-components'
import { CookiesProvider } from 'react-cookie'
import CustomUserProvider from 'contexts/CustomUserProvider'
import CustomAuthProvider from 'contexts/CustomAuthProvider'
import AppConfigProvider from 'contexts/AppConfigProvider'
import { GOOGLE_CLIENT_ID } from 'config'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ErrorBoundary from './ErrorBoundary'

const MainComponent: React.FunctionComponent<AppProps> = ({ Component, pageProps }) => {
  return (
    <ErrorBoundary>
      <Layout {...pageProps}>
        <Component {...pageProps} />
      </Layout>
    </ErrorBoundary>
  )
}

const App = (props: AppProps) => {
  // TODO:APP Remove UserProvider dep
  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID as string}>
      <CustomAuthProvider>
        <CustomUserProvider>
          <CookiesProvider>
            <AppConfigProvider>
              <ThemeProvider>
                <MainComponent {...props} />
              </ThemeProvider>
            </AppConfigProvider>
          </CookiesProvider>
        </CustomUserProvider>
      </CustomAuthProvider>
    </GoogleOAuthProvider>
  )
}

export default App
