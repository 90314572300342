import { CONFIG_ID } from 'config'
import API_CONSTANTS from './constants'
import { doGet } from 'api'
import { CMSPages } from 'interfaces/leagues'

export const getCMSPageContents = (pageId: string) => {
  return doGet(
    API_CONSTANTS.GET_CMS_PAGE_CONTENTS,
    { pageId },
    {
      headers: {
        locale: 'en-US',
        'content-type': 'application/json',
      },
    }
  )
}

export const getCMSDataForPage = async (pageIdKey: keyof CMSPages) => {
  try {
    const { data } = await getCMSPageContents(CONFIG_ID)
    const pageId: string = data?.cmsPages[pageIdKey]

    if (!pageId) throw Error
    return getCMSPageContents(pageId)
  } catch {}
}

export const getAppConfig = () => {
  return doGet(
    API_CONSTANTS.GET_APP_CONFIG,
    { isAbsUrl: true },
    {
      headers: {
        locale: 'en-US',
        'content-type': 'application/json',
      },
    }
  )
}
