// DO NOT DESTRUCTURE process.env issue: https://github.com/vercel/next.js/issues/19420

const config = {
  api: {
    // baseUrl: process.env.REACT_APP_BASEURL,
  },

  // Algolia Creds
  algoliaAppId: process.env.ALGOLIA_APP_NAME || '',
  algoliaAPIKey: process.env.ALGOLIA_KEY || '',
  marketplaceAlgoliaIndex: process.env.SEARCH_INDEX_NAME || '',
  auth: {
    timeout: 30,
  },
  BASE_API_URL: process.env.BASE_API_ENDPOINT,
  POLLING_INTERVAL: 10000,
  MAX_POLLING_API_CALLS: 30,
  CDN_ENDPOINT: process.env.CDN_ENDPOINT,
  // GROOT_BASE_URL: process.env.GROOT_BASE_URL,
  vaultAlgoliaIndex: process.env.USER_VAULT_INDEX_NAME || '',
  vaultAlgoliaPacksIndex: process.env.USER_VAULT_PACKS_INDEX_NAME || '',
  packsAlgoliaIndex: process.env.PACKS_INDEX_NAME || '',
  blockExplorerUrl: process.env.BLOCK_EXPLORER_URL || '',
  tokenContractHash: process.env.TOKEN_CONTRACT_HASH || '',
  checkoutPublicKey: process.env.CHECK_PUBLIC_KEY || '',
  RARIO_WEBSITE_URL: process.env.RARIO_WEBSITE_URL,
  SKIP_MARKETPLACE_KYC: true,
  SHOW_MOMENTS_TABS_ON_MARKETPLACE: false,
  PLATFORM_ORIGIN: process.env.PLATFORM_ORIGIN,
  PICKS_INDEX_NAME: process.env.PICKS_INDEX_NAME || '',
  INAI_TOKEN: process.env.INAI_TOKEN,
  IS_INAI_ENABLED: process.env.IS_INAI_ENABLED,
  USER_ACTIVITY_API_KEY: process.env.USER_ACTIVITY_API_KEY,
  CONFIG_ID: process.env.CONFIG_ID || '',
  RARIO_WEBVIEW_APK_URL: process.env.RARIO_WEBVIEW_APK_URL || '',
  FB_PIXEL_ID: process.env.FB_PIXEL_ID || '',
  CLEVERTAP_ID: process.env.CLEVERTAP_ID,
  IS_VPN_CHECK_ENABLED: process.env.IS_VPN_CHECK_ENABLED,
  IS_CASHFREE_ENABLED: process.env.IS_CASHFREE_ENABLED,
  CASHFREE_SANDBOX: process.env.CASHFREE_SANDBOX,
  CURRENT_APP_VERSION: process.env.CURRENT_APP_VERSION,
  SEGMENT_KEY: process.env.SEGMENT_KEY,
  DREAM_AUTH_BASE_URL: process.env.DREAM_AUTH_BASE_URL,
  DREAM_AUTH_KEY: process.env.DREAM_AUTH_KEY,
  GOOGLE_CLIENT_ID: process.env.GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET: process.env.GOOGLE_CLIENT_SECRET,
  TRUMP_GAME_SCRIPT: process.env.TRUMP_GAME_SCRIPT,
  IS_BURN_EARN_ENABLED: process.env.IS_BURN_EARN_ENABLED,
  RARIO_BASE_URL: process.env.RARIO_BASE_URL,
}

export const algoliaAppId = config.algoliaAppId
export const algoliaAPIKey = config.algoliaAPIKey
export const marketplaceAlgoliaIndex = config.marketplaceAlgoliaIndex
//  ContentFull Creds
// export const SpaceID = process.env.CONTENTFUL_SPACE_ID || ''
// export const EnvironmentID = process.env.CONTENTFUL_ENVIRONMENT_ID || ''
// export const ContentDeliveryAPIAccessToken = process.env.CONTENTFUL_ACCESS_TOKEN || ''
export const SEO_MAP_ID = process.env.SEO_MAP_ID || ''
export const CDN_ENDPOINT = config.CDN_ENDPOINT
export const vaultAlgoliaIndex = config.vaultAlgoliaIndex
export const vaultAlgoliaPacksIndex = config.vaultAlgoliaPacksIndex
export const packsAlgoliaIndex = config.packsAlgoliaIndex
export const BLOCK_EXPLORER_URL = config.blockExplorerUrl
export const TOKEN_CONTRACT_HASH = config.tokenContractHash
export const checkoutPublicKey = config.checkoutPublicKey
export const PLATFORM_ORIGIN = config.PLATFORM_ORIGIN || 'RARIO'
export const PICKS_INDEX_NAME = config.PICKS_INDEX_NAME
export const INAI_TOKEN = config.INAI_TOKEN
export const IS_INAI_ENABLED = config.IS_INAI_ENABLED
export const USER_ACTIVITY_KEY = config.USER_ACTIVITY_API_KEY
export const CONFIG_ID = config.CONFIG_ID || ''
export const RARIO_WEBVIEW_APK_URL = config.RARIO_WEBVIEW_APK_URL || ''
export const FB_PIXEL_ID = config.FB_PIXEL_ID || ''
export const CLEVERTAP_ID = config.CLEVERTAP_ID
export const IS_VPN_CHECK_ENABLED = config.IS_VPN_CHECK_ENABLED
export const IS_CASHFREE_ENABLED = config.IS_CASHFREE_ENABLED
export const CASHFREE_SANDBOX = config.CASHFREE_SANDBOX
export const CURRENT_APP_VERSION = config.CURRENT_APP_VERSION || '1.6'
export const SEGMENT_KEY = config.SEGMENT_KEY
export const DREAM_AUTH_BASE_URL = config.DREAM_AUTH_BASE_URL
export const DREAM_AUTH_KEY = config.DREAM_AUTH_KEY
export const GOOGLE_CLIENT_ID = config.GOOGLE_CLIENT_ID
export const GOOGLE_CLIENT_SECRET = config.GOOGLE_CLIENT_SECRET
export const IS_TYPESENSE_ENABLED = process.env.IS_TYPESENSE_ENABLED || ''
export const TYPESENSE_HOST = process.env.TYPESENSE_HOST || ''
export const TYPESENSE_API_KEY = process.env.TYPESENSE_API_KEY || ''
export const RECAPTCHA_SITEKEY = process.env.RECAPTCHA_SITEKEY || ''
export const RECAPTCHA_ENTERPRISE_API_KEY = process.env.RECAPTCHA_ENTERPRISE_API_KEY || ''
export const IS_RECAPTCHA_DISABLED = process.env.IS_RECAPTCHA_DISABLED === 'TRUE'
export const TRUMP_GAME_SCRIPT = process.env.TRUMP_GAME_SCRIPT || ''
export const IS_BURN_EARN_ENABLED = process.env.IS_BURN_EARN_ENABLED || ''
export const RARIO_BASE_URL = config.RARIO_BASE_URL

export default config
